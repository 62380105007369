module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PXCD29P","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Milan Laser Hair Removal","short_name":"Milan Laser","description":"Laser Hair Removal | Milan Laser is one of the nation’s leading laser hair removal companies. We are committed to providing our clients with world-class customer service and permanent results. Our physicians and highly-trained medical staff utilize ongoing medical research to ensure that all treatments are performed safely and are tailored to give every client the best results possible. Our goal is to provide 100% customer satisfaction to every client and we will do everything we can to ensure our clients are happy with their experience as well as their results.","start_url":"/","background_color":"#F2FAFD","theme_color":"#F2FAFD","display":"standalone","icon":"/opt/build/repo/theme-2023/src/images/favicon.webp","icons":[{"src":"/opt/build/repo/theme-2023/src/images/android-chrome-192x192.webp","sizes":"192x192","type":"image/png"},{"src":"/opt/build/repo/theme-2023/src/images/android-chrome-256x256.webp","sizes":"256x256","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d86e0a17ee6e0d2e70fbdb8db6f8086c"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../theme-2023/gatsby-browser.js'),
      options: {"plugins":[],"siteData":{"stores":[{"clinicName":"","stateAbbrev":"NY","zipCode":"10940","marketingCity":"Middletown","phone":"845-280-4884","cm_pardot_file":"76 - 100","open":true,"state":"New York","address":"400 NY-211 E","hero":{"reversed":false,"position":"center"},"place_id":"ChIJswTG-ugtw4kRzAcj316VSmc","pathname":"middletown","salesforceValue":"Middletown","maps":{"google":"https://goo.gl/maps/g56sPUGknPc2RXXv9","apple":"https://maps.apple.com/?address=400%20NY-211%20E,%20Middletown,%20NY%20%2010940,%20United%20States&ll=41.453666,-74.386163&q=400%20NY-211%20E&_ext=EiYpeXpbkH65REAxfxR1FhmZUsA590+B7KS6REBBQ+DttlSYUsBQBA%3D%3D&t=m"},"with_pro_images":true,"geo":{"lat":"41.455222","long":"-74.386810"},"description":{"line_one":"off of Highway 211","line_two":" in the center with Hobby Lobby and Big Lots toward the end of the center between Gamestop and Wingstop by Texas Roadhouse"},"addressCity":"","platforms":{"google":"https://www.google.com/search?q=milan+laser+hair+removal+middletown+ny&oq=milan+laser+hair+removal+middletown+ny&aqs=chrome.0.0i355i512j46i175i199i512j0i390l3j69i60l3.13954j0j7&sourceid=chrome&ie=UTF-8#lrd=0x89c32de8fac604b3:0x674a955edf2307cc,3,,,","yelp":"middletown","reviews":{"list_token":"OGFHhsAeuxpBqz0tBJpcCjNEsXVqBIAQAMQRop0l7aI8PAQeWh","slide_token":"rrIECVBqokPDy9qaAuxnJR6fDcnYteudFAXhGTaBt971qUJYfy"},"facebook":"Middletown"}}],"bing_id":"32599037","google_verification":"Xmu0Q3NPDUQFs1IEKaSTs1c5t6HGujl0Iw8tJjH-bHw","pathname":"middletown","state":"New York","openStores":1,"gtag_id":"GTM-PXCD29P","origin":"https://milanlasermiddletown.com","stateAbbrev":"NY","metroPath":"middletown","name":"Middletown","grandOpening":false,"google_analytics_id":"UA-144108258-13"}},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
